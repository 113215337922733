const requestStatus = [
    { value: 0, text: 'REQUESTED', color: '#A4A4A4' },
    { value: 1, text: 'APPROVED', color: '#45D738' },
    { value: 2, text: 'REJECTED', color: '#FF0000' },
    { value: 3, text: 'RECEIVED', color: 'info' },
]
const showString = (data) => {
    return (data === null || data === 'null' || data === undefined || data == '') ? 'N/A' : data
}

export { requestStatus, showString }