<template>
  <v-card class="primary--text" style="overflow-y: hidden;">
    <core-header :shown="true" bgcolor="" :loading="false" column="x12">
    <template slot="content">
      <!-- TITLE -->
      <v-flex xs12 md8 class="px-2" style="margin-top:-14px!important;">
          <core-title color="primary" :title="header.title" />
      </v-flex>
      <!-- TOOLBAR -->
      <v-flex xs12 md4 class="px-2" style="margin-top:-22px !important; padding-right: 0px!important">
        <core-toolbar :btnsetting="false" :btnclose="true" :btnchecklist="false" :btnrefresh="false" :btnreset="false" v-on:closePage="hFunc_closePage"></core-toolbar>
      </v-flex>
    </template>
    </core-header>
    <core-alert :flagShow="body.alert.shown" v-on:funcflagShow="body.alert.shown=false" :txtType="body.alert.type" :txtMsgAlert="body.alert.msg" />
    <v-card-text style="padding:2px 2px 0px 10px!important;">
      <div class="form-detail-asset">
        <v-row>
          <v-flex xs12 md10 class="px-2">
            <h3>Detail Info Presentase Kemenangan</h3>
          </v-flex>
          <v-flex xs12 md2 class="px-2">
            <div style="float:right; margin-top: 5px;">
              <h5><v-chip color="green">{{body.form.porsen}}</v-chip></h5>
            </div>
          </v-flex>
        </v-row>
        <v-divider></v-divider>
        <v-layout form style="padding:0px 10px 0px 0px!important;">
          <v-layout wrap align-center form col-line-one>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Kecamatan" v-model="body.form.kecName" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Kelurahan" v-model="body.form.kelName" dense/>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="TPS" v-model="body.form.tpsName" dense />
            </v-col>
            
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="DPT" v-model="body.form.iDPS" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Voters" v-model="body.form.iVoters" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Korlap" v-model="body.form.sKorlap" dense />
            </v-col>
          </v-layout>
        </v-layout>
      </div>
      <v-layout wrap align-center form col-line-one>
        <v-col cols="12" sm="12">
          <div class="form-detail-asset detail-asset">
            <v-row>
              <v-col>
                <h3 style="padding-top: 10px">Daftar DPT</h3>
              </v-col>
              <v-col style="padding: 6px 10px 0px 0px !important; margin-bottom: 0px !important;">
                <v-text-field x-small class="search" placeholder="Placeholder" outlined hide-details dense outline
                label="Search" v-model="table.search.value" append-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
            <v-divider style="padding-bottom: 6px!important"></v-divider>
              <core-table-fixed-outer-grid class="px-2 table-fleet-management" :tblHeader="table.tblHeader" backgroundHeader="#C8001D" 
                :items="table.tblData" id="tbl_exporttable_to_xls" item-key="iVotersID" :height="'auto'" :rowPerPage="-1" :disablePaging="true" :search="table.search.value">
                <!-- CUSTOME COLUMN & VALUE -->
                <template v-slot:[`item.iVoters`]="{ item }">
                    <div :style="`text-align:center;`">
                        <v-icon v-if="item.iVoters==1" title="Voters" color="#45D738" :title="item['sCarrier']">mdi-account</v-icon>
                    </div>
                </template>
             </core-table-fixed-outer-grid>
          </div>
        </v-col>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
// FUNCTION
import {success, error} from '@/consistencychecker/transactionvalidator/index'
import {showString} from '@/enums/index'
import {numberFormat} from '@/consistencychecker/formvalidator/format'
import VotersAPI from '@/services/sa/SA_Voter'

export default {
  metaInfo () { return { title: 'Quick Count' } },
  name: 'DetailVoters',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    modeEdit: {
      type: Boolean,
      default: true
    },
    data:{
      type: Object,
      default: {
        tpsID:0, kecID:0, kelID:0, sKorlap:``,
        kecName:'', kelName:'', tpsName:'', 
        dataSuara: { iDPS:0, iVoters:0 }
      }
    }
  },
  created(){
    this.getDataVoters()
  },
  data (){
    return {
      header:{
        title:"Estimasi Perolehan Suara Voters",
      },
      table:{
        search:{
          selected:``, value:``
        },
        tblHeader:this.$store.getters['app/enum'].TH_VOTERS_MAIN.filter(x=>x.value!='actions'), tblData:[]
      },
      body: {
        form:{
          kecID:showString(this.data.kecID), kelID:showString(this.data.kelID), tpsID:showString(this.data.tpsID),
          kecName:showString(this.data.kecName), kelName:showString(this.data.kelName), tpsName:showString(this.data.tpsName), 
          sKorlap:showString(this.data.sKorlap),
          iDPS:numberFormat(this.data.dataSuara.iDPS), iVoters:numberFormat(this.data.dataSuara.iVoters), porsen:`${numberFormat(this.data.dataSuara.iVoters > 0 ? parseFloat((this.data.dataSuara.iVoters/this.data.dataSuara.iDPS) * 100).toFixed(1) : 0.0)}%`,
        },
        alert:{
          shown: false,
          type: 'success',
          msg: ''
        }
      },
      footer: {
      },
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    getDataVoters(){
      this.table.tblData = []
      VotersAPI.getDataVoters(this.body.form.tpsID).then(res => {
          if (!res.status) this.handleError(res)
          this.table.tblData=res.data
      }).catch(err => {
          this.handleError(err)
          console.log('error -> Voters -> getData() :', err);
      })
    },
    table_searchValue(val){
        this.table.search.value = val
    },
    hFunc_closePage(){
      this.$emit('closePopUp');
    },
    handleSuccess(data){
      this.body.alert.shown = true
      this.body.alert.type = this.$store.getters['app/color']('s')
      this.body.alert.msg = success(data)
      setTimeout(()=>{this.body.alert.shown=false; this.show=false},3000)
    },
    handleError(err){
      this.body.alert.shown = true
      this.body.alert.type = this.$store.getters['app/color']('e')
      this.body.alert.msg = error(err)
      setTimeout(()=>{this.body.alert.shown=false},3000)
    },
  }
}
</script>
<style>
  .form-detail-asset h3 {
    font-size: 18px !important;
  }
  .status-request {
    background-color: #FFB727 !important;
    border-color: #FFB727 !important;
  }

  .detail-asset .v-image{
    max-height: 300px;
    cursor: pointer;
    height: 200px;
    width: 520px;
    margin: auto;
    border: 1px solid #dddddd;
    border-radius: 10px !important;
  }
  
  .detail-asset .v-image__image--contain{
    background-size: cover;
  }

  .form-detail-asset .form {
    padding: 8px 0 0 8px;
  }
</style>