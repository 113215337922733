<template>

	<v-layout class="flex-column homescreen">
		<v-progress-linear v-show="loadingPage.show" slot="progress" color="red" indeterminate style="margin:0!important;"></v-progress-linear>
		<v-row>
			<v-col sm="8" style="margin:5px!important">
				<v-flex xs12 md12>
					<h4 class="mb-1">Jumlah Voters <v-chip color="#15BF8C">{{ numberFormat(totalVoters) }}</v-chip> dari total DPT <v-chip>{{ numberFormat(totalDPT) }}</v-chip> pada tingkat <v-chip color="#00BAD1">{{ tingkatan }}.</v-chip> Presentase kemenangan<v-chip :style="`color:${totalPorsen>=10&&totalPorsen<=35?'#FFB727':totalPorsen>35?'#15BF8C':'#FF8186'}`">{{ numberFormat(totalPorsen) }}%</v-chip></h4> 
				</v-flex>
			</v-col>
			<v-col v-if="tingkatan!='Kecamatan'" style="margin:5px!important">
				<v-flex text-right>
					<h4 class="mb-1"><v-chip @click="backTingkatan()" title="BACK">{{ `<< ${lblDaerah}` }}</v-chip></h4>
				</v-flex>
			</v-col>
			<v-flex xs12 md12>
				<v-row class="mb-2">
					<v-flex xs12 md3 v-for="(x) in dataRekap" :key="x.id">
						<v-card class="px-2 py-2" outlined @click="setTingkatan(x.id)">
							<v-row>
								<v-col cols="12" sm="8" style="margin-bottom: 0px !important;">
									<h5>{{x.nama}}</h5>
									<p class="mb-0">
										<span :style="`color:${x.porsen>=10&&x.porsen<=35?'#FFB727':x.porsen>35?'#15BF8C':'#FF8186'}`">{{numberFormat(x.iVoters)}}</span> of <v-chip color="gray" title="Jumlah DPT" x-small>{{numberFormat(x.iDPS)}}</v-chip>
									</p>
								</v-col>
								<v-flex text-right>
									<v-chip :color="`${x.porsen>=10&&x.porsen<=35?'#FFB727':x.porsen>35?'#15BF8C':'#FF8186'}`">{{x.porsen}}%</v-chip>
								</v-flex>
							</v-row>
						</v-card>
					</v-flex>
				</v-row>
			</v-flex>
		</v-row>
		<!-- POPUP -->
        <v-main>
            <v-layout align-center justify-center style="border-radius:15px 15px 0px 0px">
                <v-dialog persistent v-model="body.showPopUp" scrollable width="1200" style="position: absolute">
                    <Detail v-if="body.showDetail" :modeEdit="true" :data="body.dataDetail" v-on:closePopUp="hFunc_closePupUp()" />
                </v-dialog>
            </v-layout>
        </v-main>
		<!-- FOOTER -->
		<core-footer :shown="true" bgcolor="white" column="x12"></core-footer>
	</v-layout>
</template>

<script>
import { message } from "@/consistencychecker/formvalidator/message";
import { success, error } from "@/consistencychecker/transactionvalidator/index";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VotingAPI from "@/services/sa/SA_Voting";
import { numberFormat } from "@/consistencychecker/formvalidator/format"
import Detail from './Detail.vue'

export default {
	components: {
		Loading, Detail
	},
	metaInfo() {
		return { title: "Quick Count" };
	},
	name: "VotingMain",
	data() {
		return {
			loadingPage: {
				show: true,
				fullPage: true
			},
			tingkatan:'Kecamatan',lblDaerah:'',selectedArea:[], 
			dataRekap:[], totalVoters:0, totalDPT:0,
			drawer: false,
			showArrows: true,
			isMobileScreen: window.screen.width >= 600,
			listInfo: true,
			listCalender: true,
			header: {
				title: "Hi, Welcome to Website QuickCount",
				subtitle: "Summary Data Voters",
			},
			body: {
				bgImg: `${process.env.VUE_APP_BASE_URL}/bg.png`,
				versi_web: `WEB-V${process.env.VUE_APP_VERSION_WEB}`,
				versi_api: `API-V${process.env.VUE_APP_VERSION_API}`,
				loading: true,
				urlGW: process.env.VUE_APP_END_GW,
				dialog: false,
				submitdis: false,
				eg: process.env.VUE_APP_END_GW + process.env.VUE_APP_BASE_URL,
				dataWilayah: [], dataSuara: [],
				showPopUp: false, showDetail: false, dataDetail:{}
			},
			footer: {
				webVersion: `WEB-V${process.env.VUE_APP_VERSION_WEB}`,
				apiVersion: `API-V${process.env.VUE_APP_VERSION_API}`,
			},
			dialog: {
				confirmChange: {
					shown: false,
					txtTittle: "Login",
					flagBody: true,
					txtBody: message.confirm[0](),
					flagBtn1: true,
					txtBtn1: "Ya",
					txtBtn2: "Tidak",
				},
			},
			showNotification: false,
		};
	},
	computed: {
		console: () => console,
		window: () => window,
	},
	created() {
		this.getDataWilayah();
		this.getDataSuara();
    },
	beforeMount() {
		this.loadingPage.show = true
		this.setDataSuaraWilayah();
	},
	mounted() {
		this.loadingPage.show = false
	},
	methods: {
		numberFormat(data){
			return numberFormat(data)
		},
		handleSuccess(data) {
			this.body.alertShow = true;
			this.body.alertType = this.$store.getters["app/color"]("s");
			this.body.alertMsg = success(data);
			setTimeout(() => { this.body.loading = false; }, 1000);
			setTimeout(() => { this.body.alertShow = false; }, 3000);
		},
		handleError(err) {
			this.body.alertShow = true;
			this.body.alertType = this.$store.getters["app/color"]("e");
			this.body.alertMsg = error(err);
			setTimeout(() => { this.body.loading = false; }, 1000);
			setTimeout(() => { this.body.alertShow = false; }, 3000);
		},
		// Function v-on update from chield (Alert & Dialog)
		bFunc_alertShow() {
			this.body.alertShow = false;
		},
		bFunc_dialogShow() {
			this.body.dialogShow = false;
		},
		hFunc_closePupUp() {
			this.body.showPopUp = false
            this.body.showDetail = false
			this.body.dataDetail = {}
		},
		backTingkatan(){
			this.loadingPage.show = true
			if(this.selectedArea.length>0){
				if(this.tingkatan=='Kelurahan'){
					// set & display data kecamatan
					this.selectedArea = []
					this.tingkatan=`Kecamatan`
					this.lblDaerah=``
				}else{
					// set & display data kelurahan
					let data = this.body.dataWilayah.filter(x=>x.kecID==this.selectedArea[0].kecID)
					this.selectedArea = data
					this.lblDaerah=`${this.selectedArea[0].kelName}`
					this.tingkatan=`Kelurahan`
				}
			}
			this.setDataSuaraWilayah();
			this.loadingPage.show = false
		},
		setTingkatan(id){
			this.loadingPage.show = true
			if(this.tingkatan=='Kecamatan'){
				// set & display data kelurahan
				this.selectedArea = this.body.dataWilayah.filter(x=>x.kecID==id)
				this.lblDaerah=`${this.selectedArea[0].kecName}`
				this.tingkatan=`Kelurahan`
				this.setDataSuaraWilayah();
				this.loadingPage.show = false
			}else if(this.tingkatan=='Kelurahan'){
				// set & display data TPS
				this.selectedArea = this.body.dataWilayah.filter(x=>x.kelID==id)
				this.lblDaerah=`${this.selectedArea[0].kelName} - ${this.selectedArea[0].kecName}`
				this.tingkatan=`TPS`
				this.setDataSuaraWilayah();
				this.loadingPage.show = false
			}else{
				// set & display data simpatisan
				this.body.dataDetail = this.body.dataWilayah.filter(x=>x.tpsID==id)[0]
				let dataSuara = this.body.dataSuara.filter(x=>x.iVotingPlaceID==id)[0]
				this.body.dataDetail[`dataSuara`] = dataSuara
				this.body.showPopUp = true
            	this.body.showDetail = true
				this.loadingPage.show = false
			}
		},
		getDataWilayah() {
			this.body.dataWilayah = JSON.parse(localStorage.getItem('dataWilayah'))
		},
		getDataSuara() {
			this.loadingPage.show = true
			VotingAPI.getDataVoting().then(data => {
				if(data.status==true){
					this.body.dataSuara = data.data;
					this.setDataSuaraWilayah();
					this.loadingPage.show = false
				}else{
					this.handleError(data.message);
					this.loadingPage.show = false
				}
			}).catch(err => { console.log('err getDataSuara', err); this.handleError(err); })
		},
		setDataSuaraWilayah() {
			// filter data sesuai tingkatan
			let field = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kecID`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`kelID`:``
			let key = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kelID`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`tpsID`:`kecID`
			let name = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kelName`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`tpsName`:`kecName`
			let value = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?this.selectedArea[0].kecID:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?this.selectedArea[0].kelID:``
			let dataWilayah = this.selectedArea.length>0 ? [... this.body.dataWilayah.filter(x=>x[field]==value)] : [... this.body.dataWilayah]
			let activearea = dataWilayah.map(x=>x.tpsID) 
			// suara RP
			let dataSuara = this.selectedArea.length>0 ? [... this.body.dataSuara.filter(x=>activearea.indexOf(x.iVotingPlaceID)>=0)] : [... this.body.dataSuara]
			// set data
			this.dataRekap = [];
			dataWilayah.reduce((res, value)=>{
				if (!res[value[key]]) {
					res[value[key]] = { id: value[key], nama: value[name], iDPS:0, iVoters:0, porsen:0.0};
					this.dataRekap.push(res[value[key]])
				}
				res[value[key]].iDPS += dataSuara.filter(i=>i.iVotingPlaceID==value.tpsID).map(x=>x.iDPS).reduce((a, b) => a + b, 0)
				res[value[key]].iVoters += dataSuara.filter(i=>i.iVotingPlaceID==value.tpsID).map(x=>x.iVoters).reduce((a, b) => a + b, 0);
				return res;
			}, {})
			this.dataRekap = this.dataRekap.map(x=>{
				x.porsen = x.iVoters > 0 ? parseFloat((x.iVoters/x.iDPS) * 100).toFixed(1) : 0.0
				return x
			})
			this.totalVoters = this.dataRekap.map(x=>x.iVoters).reduce((a, b) => a + b, 0)
			this.totalDPT = this.dataRekap.map(x=>x.iDPS).reduce((a, b) => a + b, 0)
			this.totalPorsen = this.totalVoters > 0 ? parseFloat((this.totalVoters/this.totalDPT) * 100).toFixed(1) : 0.0
		},
		refreshData(){
			this.loadingPage.show = true
			this.getDataWilayah();
			this.getDataSuara();
		}
	},
};
</script>

<style>
@media only screen and (max-width: 599px) {
	.homescreen {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.profile {
		margin-right: 0 !important;
	}

	.dotms-info {
		margin-right: 0 !important;
	}

}

.homescreen {
	padding-right: 4px;
	padding-left: 4px;
}

.profile {
	margin-right: 20px;
}

.profile h4 {
	font-size: 16px;
}

.profile p {
	font-size: 12px;
	color: #868D97;
}

.v-tooltip__content {
	background: white !important;
}

.v-tooltip__content .v-card {
	width: 110px;
}

.dotms-info {
	margin-right: 20px;
}

.profile .unit h5,
.profile .mill h5,
.profile .estate h5,
.dotms-info .need-services h5,
.dotms-info .in-services h5,
.dotms-info .services-overdue h5,
.dotms-info .operational h5,
.dotms-info .registered h5,
.dotms-info .request-update h5,
.dotms-info .request-mutation h5,
.dotms-info .expired h5 {
	font-weight: 400;
	font-size: 12px;
	color: #414042;
	height: 24px;
	line-height: 15px;
}

.profile .unit h3,
.profile .mill h3,
.profile .estate h3,
.dotms-info .need-services h3,
.dotms-info .in-services h3,
.dotms-info .services-overdue h3,
.dotms-info .operational h3,
.dotms-info .registered h3,
.dotms-info .request-update h3,
.dotms-info .request-mutation h3,
.dotms-info .expired h3 {
	font-weight: bold;
	font-size: 25px;
}

.profile .unit h3,
.dotms-info .services-overdue h3,
.dotms-info .request-mutation h3 {
	color: #00BAD1;
}

.profile .mill h3,
.dotms-info .in-services h3,
.dotms-info .request-update h3 {
	color: #FFB727;
}

.profile .estate h3,
.dotms-info .operational h3 {
	color: #15BF8C;
}

.dotms-info .need-services h3,
.dotms-info .expired h3 {
	color: #FF8186;
}

.profile .unit span,
.profile .unit .v-icon,
.profile .mill span,
.profile .mill .v-icon,
.profile .estate span,
.profile .estate .v-icon,
.dotms-info .need-services span,
.dotms-info .in-services span,
.dotms-info .services-overdue span,
.dotms-info .operational span {
	color: #15BF8C;
	border-radius: 5px;
	padding: 2px 5px 2px 2px;
	font-size: 12px !important;
}

.dotms-info .need-services span,
.dotms-info .in-services span,
.dotms-info .services-overdue span,
.dotms-info .operational span {
	background: #E3F9F2;
	padding: 0 5px;
}

.profile .unit span {
	background: #E3F9F2;
	cursor: pointer;
}

.profile .mill span {
	background: #FFE6E7;
	cursor: pointer;
}

.profile .estate span {
	background: #E3F9F2;
	cursor: pointer;
}

.notification {
	height: 321px;
}

.notification .v-list-item {
	min-height: 65px !important;
}

.application .icon-list-sidebar {
	background: linear-gradient(180deg, rgba(201, 28, 34, 0.15) 0%, rgba(217, 217, 217, 0) 100%) !important;
	width: 51.47px;
	height: 51.47px;
	margin: auto;
}

.application .icon-list-sidebar .v-image,
.application .icon-list-sidebar .v-icon {
	width: 35px;
	height: 25px;
	margin-top: 4px;
}

.v-slide-group:not(.v-slide-group--has-affixes)>.v-slide-group__prev,
.v-slide-group:not(.v-slide-group--has-affixes)>.v-slide-group__next {
	display: block;
}

.application .v-slide-group.v-item-group>.v-slide-group__next .v-icon,
.v-slide-group.v-item-group>.v-slide-group__prev .v-icon {
	width: 30px;
	height: 30px;
	border: 1px solid #DDDDDD;
	box-shadow: 0px 10px 10px rgba(227, 227, 227, 0.57);
	border-radius: 100%;
}

.application .v-list-item__icon {
	width: 100%;
}

.application .v-card {
	width: 100px;
}

.road-mapper .header-maps {
	position: inherit !important;
}

.road-mapper .searchMapper {
	margin-bottom: 20px;
}

.road-mapper .searchMapper .v-input__slot {
	padding: 8px !important;
}

.road-mapper .header-maps {
	margin: 0 !important;
}

#mapRoadScore {
	top: 190px !important;
	left: 16px !important;
	width: 97.8% !important;
	height: 77.5vh;
}

.road-mapper .header-maps {
	height: 90vh;
}
</style>